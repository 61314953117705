<template>
  <!-- 高级查询list -->
  <div style="margin-top: 80px; min-height: 500px;">
    <el-row>
      <el-col :span="24">
        <div class="container">
          <!--内容卡片 柏成大佬-->
          <el-row style="margin-bottom: 30px">
            <el-col :span="24">
              <div class="condition-content">
                <div class="dis-bet-cen condition-title">
                  <span class="screening-conditions-title">共查询到 <span class="font-color-red">{{ page.total &lt; 1900000 && page.total &gt; 1400000 ? 1954381 : page.total }}</span> 条符合条件的企业</span>
                  <span class="advanced-query-title">
                  <el-select v-model="optionsValue" placeholder="默认排序">
                      <el-option
                        v-for="item in options"
                        :key="item.value"
                        :label="item.label"
                        :value="item.value">
                      </el-option>
                    </el-select>
                </span>
                </div>
                <div class="condition-content-list">
                  <!-- 企业 -->
                  <block v-show="!fuzzyDataShow" v-for="(item, index) of dataList" :key="index">
                    <el-row class="list-padding list-hover">
                      <el-col :span="24">
                        <div class="condition-content-list-name dis-sta-cen">
                          <span style="cursor:pointer;" @click="companyNameClick(item, index)" v-html="item.companyName || '-'"></span>
                          <span v-if="item.registerStatStr === '存续' || item.registerStatStr === '在业'||item.registerStatStr === '在营' || item.registerStatStr === '在营（开业）企业'||item.registerStatStr === '存续（在营、开业、在册）'||item.registerStatStr === '开业'||item.registerStatStr === '正常'||item.registerStatStr === ' 迁入'||item.registerStatStr === '迁出'" class="tag-green">{{item.registerStatStr }}</span>
                          <span v-else-if="item.registerStatStr === '注销'||item.registerStatStr === '吊销，已注销'||item.registerStatStr === '吊销，未注销'||item.registerStatStr === '吊销企业'||item.registerStatStr === '已吊销'||item.registerStatStr === '异常'||item.registerStatStr === '注销企业'" class="tag-red">{{item.registerStatStr }}</span>
                          <span v-else class="tag-grey">暂无</span>
                        </div>
                      </el-col>
                      <el-col :span="24">
                        <el-row :gutter="20" class="condition-content-list-title">
                          <el-col :span="5">法定代表人：<span class="font-color-gray">{{item.corporation || '-'}}</span></el-col>
                          <el-col :span="5">成立时间：<span class="font-color-gray">{{item.regDateStr || '-'}}</span></el-col>
                          <el-col :span="5">注册资本：<span class="font-color-gray">{{item.regMoney || '-'}}</span></el-col>
                        </el-row>
                      </el-col>
                      <el-col :span="24">
                        <el-row :gutter="20" class="condition-content-list-title">
                          <el-col :span="5">电话：<span class="font-color-gray">{{item.userMobile || '-'}}</span></el-col>
                          <el-col :span="5">邮箱：<span class="font-color-gray">{{item.userEmail || '-'}}</span></el-col>
                          <el-col :span="5">网站：<a class="font-color-a-blue" :href="item.website">{{item.website || '-'}}</a></el-col>
                        </el-row>
                      </el-col>
                      <el-col :span="24">
                        <el-row :gutter="20" class="condition-content-list-title">
                          <el-col :span="10">地址：<span class="font-color-gray">{{item.regAddress || '-'}}</span></el-col>
                        </el-row>
                      </el-col>
                      <div style="cursor:pointer;" @click="focusClick(item, index)" :class="item.foucsType === 2 ? 'focus-enterprises-tag' : 'focus-enterprises-tag-gray'"><span class="icon iconfont" style="font-size: 12px;margin-right: 5px;">{{item.foucsType === 2 ? '&#xe601;' : '&#xe615;'}}</span>{{item.foucsType === 2 ? '已关注' : '关注' }}</div>
                    </el-row>
                    <el-divider v-if="(index + 1) != dataList.length"></el-divider>
                  </block>
                  <el-empty v-if="dataList.length === 0"  :image-size="200"></el-empty>
                  <gaussian-blur pageName="company-achievement" v-show="fuzzyDataShow"></gaussian-blur>
                </div>
              </div>
            </el-col>
          </el-row>
          <!--分页组件-->
          <div class="dis-end-sta" style="margin-bottom: 30px;">
            <el-pagination
              :hide-on-single-page="true"
              :pager-count="page.pagerCount"
              background
              layout="prev, pager, next"
              v-model:currentPage="page.pageNum"
              @current-change="currentChange"
              :total="page.total">
            </el-pagination>
          </div>
        </div>
      </el-col>
    </el-row>
  </div>
</template>

<script>
import { forwardBG, oldForwardBG } from '@/api/forward.js'
import GaussianBlur from '@/components/gaussian-blur/gaussian-blur'
import {
  getFoucsByUserIdAndCompanyIds,
  InsertUserFoucs,
  DelByUserIdAndCompanyId
} from '@/api/general-query/company'
import { parseTime } from '@/utils/index' // 工具类
import { ElMessage } from 'element-plus'
import { mapGetters } from 'vuex'
export default {
  name: 'company',
  setup (props, context) {
    function loginEmit () {
      context.emit('loginEmit', 'topNav')
    }
    return {
      loginEmit
    }
  },
  components: {
    GaussianBlur
  },
  data () {
    return {
      // 数据
      dataList: [
        // {
        //   name: '厦门柏事特信息技术有限公司',
        //   address: '厦门市软件园二期观日路24号101-C单元',
        //   tel: '0592-2273296',
        //   people: '陈京鹭',
        //   isfocus: true,
        //   status: ' 存续',
        //   inter: 'http://www.xmbest.com',
        //   email: 'xmbest@xmbest.com',
        //   time: '2000-04-28',
        //   money: '5006万元人民币'
        // },
        // {
        //   name: '厦门柏事特信息技术有限公司',
        //   address: '厦门市软件园二期观日路24号101-C单元',
        //   tel: '0592-2273296',
        //   people: '陈京鹭',
        //   isfocus: false,
        //   status: ' 存续',
        //   inter: 'http://www.xmbest.com',
        //   email: 'xmbest@xmbest.com',
        //   time: '2000-04-28',
        //   money: '5006万元人民币'
        // }
      ],
      // 排序的筛选
      optionsValue: '1',
      options: [{
        value: '1',
        label: '默认排序'
      }, {
        value: '2',
        label: '成立时间升序'
      }, {
        value: '3',
        label: '成立时间降序'
      }, {
        value: '4',
        label: '中标金额升序'
      }, {
        value: '5',
        label: '中标金额降序'
      }],
      page: { // 分页使用
        total: 0, // 总条数
        pagerCount: 7,
        pageNum: 1, // 当前页
        pageSize: 10 // 每页条数
      },
      queryConditions: {},
      returnLimit: false, // 返回数据是否受限
      fuzzyDataShow: false, // 模糊图片是否显示
      fuzzyData: require('@/assets/images/fuzzy_data.png')
    }
  },
  computed: {
    // vuex获取值
    // this.$store.user.vipData = obj
    ...mapGetters([
      'vipData', // 会员数据
      'idToken',
      'userInfo'
    ])
  },
  watch: {
    optionsValue () {
      const vm = this
      if (vm.idToken) {
        if (vm.returnLimit) { // 已经返回受限了 就不继续查询了
          vm.page.pageNum = -Math.ceil(Math.random() * 1000)
          vm.fuzzyDataShow = true
        } else {
          // 去查询
          vm.getList()
        }
      } else {
        vm.optionsValue = '0'
        vm.loginEmit()
      }
    }
  },
  mounted () {
    const vm = this
    vm.initQuery()
  },
  methods: {
    // 翻页时触发
    currentChange (cuttentPage) {
      const vm = this
      if (vm.idToken) {
        if (vm.returnLimit) { // 已经返回受限了 就不继续查询了
          vm.page.pageNum = -Math.ceil(Math.random() * 1000)
          vm.fuzzyDataShow = true
        } else {
          document.documentElement.scrollTop = 0
          vm.page.pageNum = cuttentPage
          vm.getList()
        }
      } else {
        vm.page.pageNum = -Math.ceil(Math.random() * 1000)
        vm.loginEmit()
      }
    },
    initQuery () {
      const vm = this
      const highQueryConditionsItem = sessionStorage.getItem('high-query-conditions-item')
      if (highQueryConditionsItem) {
        vm.queryConditions = JSON.parse(highQueryConditionsItem)
      }
      vm.getList()
    },
    getList () {
      const vm = this
      const loading = this.$loading({
        lock: true,
        text: '加载中...',
        spinner: 'el-icon-loading',
        background: 'rgba(0, 0, 0, 0.7)'
      })
      let orderField = null
      let orderType = null
      // 处理排序
      if (vm.optionsValue === '1') {
        orderField = null
        orderType = null
      } else if (vm.optionsValue === '2') {
        orderField = 'reg_date'
        orderType = 'asc'
      } else if (vm.optionsValue === '3') {
        orderField = 'reg_date'
        orderType = 'desc'
      } else if (vm.optionsValue === '4') {
        orderField = 'reg_money_num'
        orderType = 'asc'
      } else if (vm.optionsValue === '5') {
        orderField = 'reg_money_num'
        orderType = 'desc'
      }
      vm.queryConditions.pageNum = vm.page.pageNum
      vm.queryConditions.pageSize = vm.page.pageSize
      vm.queryConditions.orderField = orderField
      vm.queryConditions.orderType = orderType
      const data = {
        dateBackgroundUrl: vm.idToken ? '/esComplexInfo/getESComplexInfoListByModel' : '/esFake/esComplexInfo/getESComplexInfoListByModel',
        dateBackgroundRequestType: 'post',
        data: vm.queryConditions
      }
      const forward = vm.idToken ? oldForwardBG : forwardBG
      forward(data).then(result => {
        if (result.code === 0 || result.code === 97 || result.code === 98) {
          if (result.code === 97) {
            vm.returnLimit = true
            vm.fuzzyDataShow = false
          } else if (result.code === 98) {
            vm.returnLimit = true
            vm.fuzzyDataShow = true
          } else {
            vm.returnLimit = false
            vm.fuzzyDataShow = false
          }
          const data = result.data ? result.data : []
          const thisDataList = []
          const companyIds = []
          for (let i = 0; i < data.length; i++) {
            if (i === 0) {
              vm.page.total = data[i].dataTotal
            }
            if (data[i].registerStat) {
              data[i].registerStatStr = data[i].registerStat.substring(0, 2)
            }
            if (data[i].regDate) {
              data[i].regDateStr = parseTime(data[i].regDate, '{y}-{m}-{d}')
            }
            data[i].foucsType = 1 // 1未关注 2已关注
            thisDataList.push(data)
            companyIds.push(data[i].companyId)
          }
          vm.dataList = data
          // 去查询ES中没有的字段
          if (companyIds.length > 0) {
            vm.getListOtherData(companyIds)
            if (vm.idToken) {
              // 查询是否关注
              vm.getListFocus(companyIds)
            }
          }
        } else {
          return ElMessage.error(result.msg)
        }
        loading.close()
      }).catch(error => {
        console.log(error)
        loading.close()
      })
    },
    getListOtherData (companyIds) {
      const vm = this
      const data = {
        dateBackgroundUrl: '/company/getCompanyByIds',
        dateBackgroundRequestType: 'get',
        data: {
          ids: companyIds.toString()
        }
      }
      forwardBG(data).then(result => {
        if (result.code === 0) {
          const data = result.data ? result.data : []
          for (let j = 0; j < vm.dataList.length; j++) {
            for (let i = 0; i < data.length; i++) {
              if (vm.dataList[j].companyId === data[i].id) {
                vm.dataList[j].userMobile = data[i].userMobile
                vm.dataList[j].userEmail = data[i].userEmail
                vm.dataList[j].website = data[i].website
              }
            }
          }
        } else {
          return ElMessage.error(result.msg)
        }
      }).catch(error => {
        console.log(error)
      })
    },
    // 查询是否关注
    getListFocus (companyIds) {
      const vm = this
      if (vm.vipData && companyIds && companyIds.toString()) {
        const data = {
          userId: JSON.parse(vm.vipData).userId,
          companyIds: companyIds.toString()
        }
        getFoucsByUserIdAndCompanyIds(data).then(result => {
          if (result.code === 0) {
            const data = result.data ? result.data : []
            for (let j = 0; j < vm.dataList.length; j++) {
              for (let i = 0; i < data.length; i++) {
                if (vm.dataList[j].companyId === data[i]) {
                  vm.dataList[j].foucsType = 2
                }
              }
            }
          } else {
            return ElMessage.error(result.msg)
          }
        }).catch(error => {
          console.log(error)
        })
      }
    },
    // 点击了关注
    focusClick (item, index) {
      const vm = this
      if (vm.idToken && item.companyId) {
        const data = {
          userId: JSON.parse(vm.vipData).userId,
          companyId: item.companyId
        }
        if (item.foucsType === 2) { // 已经关注了 去取消
          DelByUserIdAndCompanyId(data).then(result => {
            if (result.code === 0) {
              vm.dataList[index].foucsType = 1
            } else {
              return ElMessage.error(result.msg)
            }
          }).catch(error => {
            console.log(error)
          })
        } else if (item.foucsType === 1) { // 没关注 去关注
          InsertUserFoucs(data).then(result => {
            if (result.code === 0) {
              vm.dataList[index].foucsType = 2
            } else {
              return ElMessage.error(result.msg)
            }
          }).catch(error => {
            console.log(error)
          })
        }
      } else {
        console.log('没登录去登录')
      }
    },
    // 点击了 公司名 跳转到公司详情
    companyNameClick (item, index) {
      const vm = this
      console.log(JSON.stringify(item) + ' --- ' + index)
      vm.$store.dispatch('setBusId', item.companyId)
      vm.$router.push({ name: 'enterprise-details' })
    }
  }
}
</script>

<style scoped="scoped" lang="less">
.focus-enterprises-tag {
  position: absolute;
  top: 50%;
  right: 20px;
  height: 30px;
  margin-top: -17px;
  border: 1px solid #5D6FE9;
  color: #5D6FE9;
  line-height: 30px;
  padding: 0 5px;
  font-size: 14px;
  cursor: pointer;
}
.focus-enterprises-tag-gray {
  position: absolute;
  top: 50%;
  right: 20px;
  height: 30px;
  margin-top: -17px;
  border: 1px solid #E6E6E6;
  color: #666;
  line-height: 30px;
  padding: 0 5px;
  font-size: 14px;
  cursor: pointer;
}
.focus-enterprises-tag-yellow {
  color: #EA9920;
  padding: 0 5px;
  border: 1px solid #EA9920;
  cursor: pointer;
  font-size: 12px;
  margin-left: 10px;
}
.el-divider--horizontal {
  margin: 0;
}
.list-hover:hover{
  background-color: #F3F9FD;
  cursor: pointer;
}
</style>
